<template>
	<div>
		<page-title></page-title>
		<div :class="project_id == 72 ? 'class_input' : ''" class="mt-1">
			<b-tabs
				content-class="col-12 col-md-10 mt-2 mt-md-0"
				nav-class="nav-left"
				nav-wrapper-class="col-md-2 col-12 nav-vertical"
				vertical
			>
				<!-- general tab -->
				<b-tab active>
					<!-- title -->
					<template #title>
						<feather-icon class="mr-1" icon="UserIcon" size="18" />
						<span class="font-weight-bold">{{
							$t("General")
						}}</span>
					</template>

					<user-edit-tab-account :user-data="userData" />
				</b-tab>
				<!--/ general tab -->

				<!-- change password tab -->
				<b-tab>
					<!-- title -->
					<template #title>
						<feather-icon class="mr-1" icon="LockIcon" size="18" />
						<span class="font-weight-bold">{{
							$t("Change Password")
						}}</span>
					</template>

					<account-setting-password
						:class="project_id == 72 ? 'sub_card_bg' : ''"
					/>
				</b-tab>
				<!--/ change password tab -->

				<!--        set theme-->
				<b-tab>
					<!-- title -->
					<template #title>
						<feather-icon class="mr-1" icon="LockIcon" size="18" />
						<span class="font-weight-bold">{{
							$t("System Theme")
						}}</span>
					</template>

					<theme-panel />
				</b-tab>
			</b-tabs>
		</div>
	</div>
</template>

<script>
import { BTab, BTabs } from "bootstrap-vue";
import AccountSettingPassword from "./AccountSettingPassword.vue";
import PageTitle from "@/views/layout/PageTitle";
import UserEditTabAccount from "@/views/admin/user/users-edit/UserEditTabAccount.vue";
import ThemePanel from "@/views/admin/theme/panel/ThemePanel";
import { computed, onMounted, reactive, toRefs } from "@vue/composition-api";
import store from "@/store";
import { showToast } from "@/libs/utils/showToast";
import { routerParams } from "@/libs/utils/routerParams";

export default {
	components: {
		PageTitle,
		BTabs,
		BTab,
		UserEditTabAccount,
		AccountSettingPassword,
		ThemePanel,
	},
	setup() {
		const blankUserData = {
			user_id: 0,
			user_name: "新用户",
			user_alias: "",
			user_avatar: "",
			user_data: "",
			password: "",
			email: "",
			phone: "",
			description: "",
			update_time: "",
		};
		const event = reactive({
			user_id: computed(() => {
				let user_data = JSON.parse(localStorage.getItem("userData"));
				return user_data.user_id;
			}),
			project_id: computed(() => {
				return routerParams("project_id");
			}),
			userData: blankUserData,
		});
		const getUserData = () => {
			if (event.user_id) {
				store
					.dispatch("users/fetchUser", event.user_id)
					.then((response) => {
						event.userData = response.data;
					})
					.catch((error) => {
						showToast(error);
					});
			}
		};

		onMounted(() => {
			getUserData();
		});

		return {
			...toRefs(event),
		};
	},
	data() {
		return {
			options: {},
		};
	},
};
</script>
<style lang="scss" scoped>
@import "../../../assets/scss/project-jsf/jsf";

// 账号设置
.class_input {
	// 文字
	& ::v-deep .text-muted {
		@include text-color("text-color-1");
	}

	& ::v-deep label,
	h3 {
		@include text-color("text-color-1");
	}

	& ::v-deep h3 {
		@include text-color("text-color-1");
	}

	// card
	& ::v-deep .card {
		@include bg-color-2("bg-color-2");
	}

	& ::v-deep input {
		@include text-color("text-color-1");
		@include bg-color-2("sub-card-color");

		&::placeholder {
			@include text-color("text-color-1");
		}
	}

	& ::v-deep .btn-primary {
		@include bg-color-2("light-bg-color");
		@include line_color("light-bg-color");
		@include text-color("text-2");
	}

	& ::v-deep .input-group {
		.input-group-text {
			@include bg-color-2("sub-card-color");
			// &:foucs{
			// 	@include line_color("line-color-1")
			// }
		}
	}

	& ::v-deep .form-control:focus {
		@include line_color("line-color-1");
	}

	& ::v-deep .form-control:focus + .input-group-append {
		.input-group-text {
			@include line_color("line-color-1");
		}
	}

	// 头像背景
	& ::v-deep .b-avatar.badge-secondary {
		// @include bg-color-2("title_color");
		@include bg-color-2("bg-color-2");
	}

	// tabs
	& ::v-deep .nav {
		.nav-item {
			.nav-link {
				background: transparent !important;
				border: 1px solid transparent;
				@include text-color("text-color-1");

				&.active,
				&:active {
					@include text-2("text-2");
					@include bg-color-2("light-bg-color");
					@include box-shadow("main-color");
				}

				&:after {
					@include bg-color-2("sub-card-color");
				}
			}
		}
	}
}
</style>
