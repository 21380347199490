<template>
	<b-card>
		<b-container class="bv-example-row">
			<b-row>
				<b-col class="d-flex justify-content-center align-items-center">
					<div v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="yt" @click="showTheme">yello_theme</div>
				</b-col>
				<b-col class="d-flex justify-content-center align-items-center">
					<div v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="pt" @click="showTheme">purple_theme</div>
				</b-col>
			</b-row>
		</b-container>
	</b-card>
</template>

<script>
	import Ripple from "vue-ripple-directive";
	import { showToast } from "@/libs/utils/showToast";
	import store from "@/store";

	export default {
		name: "ThemePanel",
		directives: {
			Ripple,
		},
		methods: {
			showTheme(e) {
				e.target.innerText && localStorage.setItem("theme", e.target.innerText);
				store.commit("jsf/CHANGE_theme", e.target.innerText);
				document.body.setAttribute("data-theme", store.state.jsf.theme);
				showToast(null, "成功！", "锦什坊街叁拾伍号主题设为！" + e.target.innerText);
			},
		},
	};
</script>
<style lang="scss" scoped>
	.panel {
		width: 10rem;
		height: 10rem;
		font-size: 1.2rem;
		font-weight: bold;
		color: white;
		text-align: center;
		line-height: 10rem;
		align-items: center;
	}

	.yt {
		background: #80725e;
		@extend .panel;
	}

	.pt {
		background: #7367f0;
		@extend .panel;
	}
</style>
