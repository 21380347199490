<template>
	<div>
		<b-media class="mb-2">
			<template #aside>
				<b-avatar ref="previewEl" :src="String(blankUserData.user_avatar)" :text="avatarText(blankUserData.user_name)" class="round" size="100px" />
			</template>
			<h3 class="font-title">
				{{ blankUserData.user_name }}
			</h3>
			<div class="d-flex flex-wrap">
				<b-button size="sm" variant="primary" @click="$refs.refInputEl.$el.click()">
					<b-form-file ref="refInputEl" :hidden="true" accept=".jpg, .png, .gif" plain @input="uploadAvatar" />
					<feather-icon icon="UploadIcon" />
					<span class="ml-1">{{ $t("Update") }}</span>
				</b-button>
				<b-button class="ml-1" size="sm" variant="outline-secondary" @click="clearUserAvatar">
					<feather-icon icon="TrashIcon" />
					<span class="ml-1">{{ $t("Remove") }}</span>
				</b-button>
			</div>
			<p class="small mt-50 text-muted">图片格式：JPG/GIF/PNG &nbsp; 最大尺寸：1M</p>
		</b-media>
		<!-- User Info: Input Fields -->
		<!-- 验证 -->
		<validation-observer ref="add_editForm" #default="{ handleSubmit }">
			<b-form @submit.prevent="handleSubmit(editConfirm)">
				<b-row>
					<!-- Field: Username -->
					<b-col cols="12" md="6">
						<b-form-group :label="$t('UserName')" label-for="user_name">
							<validation-provider #default="{ errors }" name="userName" rules="required" vid="userName">
								<b-form-input id="user_name" v-model="blankUserData.user_name" :state="errors.length > 0 ? false : null" name="user_name" />
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>

					<b-col v-if="!blankUserData.user_id" cols="12" md="6">
						<validation-provider #default="{ errors }" name="Password" rules="required" vid="password">
							<b-form-group :label="$t('Password')" label-for="password">
								<b-form-input id="password" v-model="blankUserData.password" :state="errors.length > 0 ? false : null" type="password" />
								<small class="text-danger">{{ errors[0] }}</small>
							</b-form-group>
						</validation-provider>
					</b-col>

					<!-- Field: Email -->
					<b-col cols="12" md="6">
						<b-form-group :label="$t('Email_U')" label-for="email">
							<b-form-input id="email" v-model="blankUserData.email" type="email" />
						</b-form-group>
					</b-col>

					<!-- Field: Phone -->
					<b-col cols="12" md="6">
						<b-form-group :label="$t('Phone')" label-for="phone">
							<b-form-input id="phone" v-model="blankUserData.phone" />
						</b-form-group>
					</b-col>
				</b-row>

				<div class="mt-1">
					<b-button class="mr-1" size="sm" type="submit" variant="primary">
						<feather-icon icon="SaveIcon" />
						<span class="ml-1">{{ $t("Save Change") }}</span>
					</b-button>
					<b-button size="sm" type="reset" variant="outline-secondary" @click="resetButton">
						<feather-icon icon="RefreshCcwIcon" />
						<span class="ml-1">{{ $t("Reset") }}</span>
					</b-button>
				</div>

				<div v-if="blankUserData.update_time" class="mt-50 small text-muted">{{ $t("UpdateTime") }}：{{ blankUserData.update_time }}</div>
			</b-form>
		</validation-observer>
	</div>
</template>

<script>
	import { avatarText } from "@core/utils/filter";
	import vSelect from "vue-select";
	import store from "@/store";
	import { required } from "@validations";
	import { ValidationObserver, ValidationProvider } from "vee-validate";
	import { onMounted, reactive, toRefs, watch } from "@vue/composition-api";
	import { cos_upload } from "@/libs/utils/uploadFiles";
	import { showToast } from "@/libs/utils/showToast";
	import router from "@/router";

	export default {
		components: {
			ValidationObserver,
			ValidationProvider,
			vSelect,
		},
		props: {
			userData: [Object, null],
		},
		setup(props, ctx) {
			const blankUserData = {
				user_id: 0,
				user_name: "新用户",
				user_alias: "",
				user_avatar: "",
				user_data: "",
				password: "",
				email: "",
				phone: "",
				description: "",
				update_time: "",
			};
			const event = reactive({
				blankUserData: blankUserData,
				refInputEl: null,
				previewEl: null,
				required,
			});

			watch(
				() => props.userData,
				(userData) => {
					if (userData) {
						setDefaultUserData();
					}
				}
			);

			const setDefaultUserData = (reset = false) => {
				let userData = reset || !props.userData ? blankUserData : props.userData;
				event.blankUserData = JSON.parse(JSON.stringify(userData));
				store.commit("users/UPDATE_USER_USERDATA", userData);
			};

			const editConfirm = () => {
				ctx.refs.add_editForm.validate().then((success) => {
					if (success) {
						if (!event.blankUserData.user_id) {
							store
								.dispatch("users/addUser", event.blankUserData)
								.then((response) => {
									showToast(null, "添加完成", `您已成功添加用户`);
									store.commit("users/UPDATE_USER_USERDATA", response.data);
									router.push({
										path: "/admin/users/list",
									});
								})
								.catch((error) => {
									showToast(error);
								});
						} else {
							store
								.dispatch("users/editUser", event.blankUserData)
								.then((response) => {
									store.commit("users/UPDATE_USER_USERDATA", response.data);
									showToast(null, "修改完成", `您已成功修改该用户的基本信息`);
									router.push({
										path: "/admin/users/list",
									});
								})
								.catch((error) => {
									showToast(error);
								});
						}
					}
				});
			};
			const resetButton = () => {
				setDefaultUserData(true);
			};
			const clearUserAvatar = () => {
				event.blankUserData.user_avatar = null;
			};

			const uploadAvatar = () => {
				const file = event.refInputEl.files[0];
				cos_upload(
					file,
					(progress) => {
						console.log("progress", progress);
					},
					(res) => {
						console.log("success");
						event.blankUserData.user_avatar = "https://" + res.Location;
					},
					"uploads/users"
				);
			};
			onMounted(() => {
				setDefaultUserData();
			});
			return {
				...toRefs(event),
				avatarText,
				editConfirm,
				resetButton,
				uploadAvatar,
				clearUserAvatar,
			};
		},
	};
</script>

<style lang="scss">
	@import "src/@core/scss/vue/libs/vue-select.scss";
</style>
